import Box from "@mui/material/Box";
import { ReactElement} from "react";
import StepHeader from "../StepHeader";
import VerificationCodeInput from "../VerificationCodeInput";
import CabSpinner from "@CabComponents/CabSpinner";


export interface VerifyEmailProps {
  email: string;
  loading?: boolean;
  errorMessage?: string[];
  offerNewCode?: boolean;
  onResendCode: () => void;
  onCodeEntered: (value: string) => void;
  toastMessage: string;
  onClearToastMessage: () => void;
}


const VerifyEmailStep = ({ 
  email, loading, errorMessage, onCodeEntered, onResendCode, offerNewCode, toastMessage, onClearToastMessage
}: VerifyEmailProps): ReactElement => {

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={9} width="100%">
      <StepHeader
        title="Verify your account"
        text={<span>We've sent a verification code to <b>{email}</b></span>}
      />
      <VerificationCodeInput
        length={6}
        label='Enter your code:'
        placeholder='-'
        autofocus
        autoSubmit
        loading={loading}
        offerNewCode={offerNewCode}
        errorMessage={errorMessage}
        onResendCode={onResendCode}
        onComplete={onCodeEntered}
        toastMessage={toastMessage}
        onClearToastMessage={onClearToastMessage}
      />
      {loading && <CabSpinner scale={4} color='inherit' />}
      <div>
        Didn't receive an email?&nbsp; 
        <span style={{cursor:'pointer', textDecoration: 'underline'}} onClick={onResendCode}>
          Request a new code
        </span>
      </div>
    </Box>
  );
};

export default VerifyEmailStep;